import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-family: 'Space Grotesk', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
`;

export const Footer = () => {
  return (
    <Container>
      © CarbonKitties 2022
    </Container>
  );
};
