import React from 'react';
import styled from 'styled-components';

import iconSrc from '../assets/scratch.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  position: relative;
`;

const Header = styled.div`
  font-family: 'Special Elite', sand-serif;
  font-size: 85px;
  color: #EB53AE;
  @media(max-width: 820px) {
    font-size: 38px;
    margin-top: 10px;
  }
`;

const SubHeader = styled.div`
  font-family: 'Space Grotesk', sans-serif;
  font-size: 24px;
  color: rgba(2, 2, 1, 0.6);
  text-align: center;
  padding: 0 20px;
  line-height: 30px;
  @media(max-width: 820px) {
    font-size: 19px;
  }
`;

const Icon = styled.img`
  position: absolute;
  left: 40px;
  top: 40px;
  width: 75px;
  @media(max-width: 820px) {
    left: 10px;
    top: 10px;
    width: 35px;
  }
`;

const Button = styled.button`
  margin-top: 18px;
  font-family: 'Space Grotesk',sans-serif;
  background: #4AABF8;
  font-size: 18px;
  border: none;
  padding: 7px 19px;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
`;

export const Title = () => {
  return (
    <Container>
      <Icon alt="scratch" src={iconSrc} />
      <Header>CarbonKitties</Header>
      <SubHeader>Decarbonize your town and purr in the CarbonKitties</SubHeader>
      <Button
        onClick={() => window.open('https://forms.gle/NWiGqS5kB5uSfZSb9', '_blank', 'noopener,noreferrer')}
      >
        NOTIFY ME
      </Button>
    </Container>
  );
};