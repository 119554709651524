import React from 'react';
import styled from 'styled-components';

import { Title } from './components/Title';
import { Graphics } from './components/Graphics';
import { Banner } from './components/Banner';
import { Footer } from './components/Footer';

const Container = styled.div`
  background-color: #ECECEC;
  min-height: 100vh;
  overflow: hidden;
`;

const Content = styled.div`
  min-height: calc(100vh - 50px);
`;

function App() {
  return (
    <Container>
      <Content>
        <Title />
        <Graphics />
        <Banner />
      </Content>
      <Footer />
    </Container>
  );
}

export default App;
