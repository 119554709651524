import React from 'react';
import styled from 'styled-components';

import mainGraphics from '../assets/main-graphics.png';
import animationLayer1 from '../assets/kitty-box-animation/layer1.svg';
import animationLayer2 from '../assets/kitty-box-animation/layer2.svg';
import animationLayer3 from '../assets/kitty-box-animation/layer3.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
  position: relative;
`;

const Image = styled.img`
 width: 800px;
`;

const AnimationLayer1 = styled.img`
  position: absolute;
  top: -119px;
  scale: 0.7;
`;

const AnimationLayer2 = styled.img`
  position: absolute;
  top: 5px;
  transform: translate(-8px, 0);
  scale: 0.7;
  @keyframes moving {
    0% {
      top: 5px;
    }
    50% {
      top: -24px;
    }
    55% {
      top: 5px;
    }
    100% {
      top: 5px;
    }
  }
  animation-name: moving;
  animation-duration: 10s;
  animation-iteration-count: infinite;
`;

const AnimationLayer3 = styled.img`
  position: absolute;
  top: -18px;
  scale: 0.7;
`;

export const Graphics = () => {
  return (
    <Container>
      <AnimationLayer1 alt="animation-layer-1" src={animationLayer1} />
      <AnimationLayer2 alt="animation-layer-2" src={animationLayer2} />
      <AnimationLayer3 alt="animation-layer-3" src={animationLayer3} />
      <Image src={mainGraphics} />
    </Container>
  );
};
