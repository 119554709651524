import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4AABF8;
  margin: 40px 0 40px 0;
  overflow: hidden;
  max-width: 100vw;
  width: 100vw;
  flex-direction: row;
  position: relative;
`;

const Content = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  @keyframes rotate {
    from {margin-left: 0;}
    to {margin-left: -14000px;}
  }
  animation-name: rotate;
  animation-duration: 200s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

const Space = styled.div`
  width: 20px;
`;

const Item = styled.div`
  font-weight: 600;
  font-size: 20px;
  width: 220px;
  font-family: 'Space Grotesk', sans-serif;
`;

export const Banner = () => {
  return (
    <Container>
      <Content>
        <Item>COMING SOON</Item><Space /><Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space />
        <Item>COMING SOON</Item><Space /><Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space />
        <Item>COMING SOON</Item><Space /><Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space />
        <Item>COMING SOON</Item><Space /><Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space />
        <Item>COMING SOON</Item><Space /><Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space />
        <Item>COMING SOON</Item><Space /><Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space />
        <Item>COMING SOON</Item><Space /><Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space />
        <Item>COMING SOON</Item><Space /><Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space /> <Item>COMING SOON</Item><Space />
      </Content>
    </Container>
  );
};
